<template>
    <div class="blog">
        <header>
            <!-- Header Start -->
            <div class="header-area">
                <div class="main-header ">
                    <div class="header-top d-none d-lg-block">
                        <div class="container-fluid">
                            <div class="col-xl-5">
                                <div class="row d-flex justify-content-between align-items-center">
                                    <div class="header-info-left d-flex">
                                        <ul>     
                                            <li>Email: bikeangel_b@163.com</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-bottom  header-sticky">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <!-- Logo -->
                                <div class="col-xl-2 col-lg-2">
                                    <div class="logo logo_an">
                                        <a href="#"><img style="width: 100px;height: auto;" src="../static/img/logo/logo.png" alt=""></a>
                                        <div class="logo_an_content">
                                            <h3>頁面導航</h3>
                                            <ul id="navigation">                                                                                    
                                                <li><h4><router-link to="/hello">首頁</router-link></h4></li>
                                                <li><h4><router-link to="/blog">活動頁面</router-link></h4></li>
                                                <li><h4><router-link to="/about">故事墻</router-link></h4></li>
                                                <li><h4><router-link to="/photos">照片墻</router-link></h4></li>
                                            </ul>
                                            <router-link to="/contact" class="btn header-btn">加入我們</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-lg-10">
                                    <div class="menu-wrapper  d-flex align-items-center justify-content-end">
                                        <!-- Main-menu -->
                                        <div class="main-menu d-none d-lg-block">
                                            <nav>
                                                <ul id="navigation">                                                                                          
                                                    <li><router-link to="/hello">首頁</router-link></li>
                                                    <li><router-link to="/blog">活動頁面</router-link></li>
                                                    <li><router-link to="/about">故事墻</router-link></li>
                                                    <li><router-link to="/photos">照片墻</router-link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <!-- Header-btn -->
                                        <div class="header-right-btn d-none d-lg-block ml-20">
                                            <router-link to="/contact" class="btn header-btn">加入我們</router-link>
                                        </div>
                                    </div>
                                </div> 
                                <!-- Mobile Menu -->
                                <div class="col-12">
                                    <div class="mobile_menu d-block d-lg-none"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Header End -->
        </header>
        <main>
            <!--? Hero Start -->
            <div class="slider-area2">
                <!-- <img src="../static/img/hero/hero2.png" alt=""> -->
                <div class="slider-height2 d-flex align-items-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap hero-cap2 pt-70 text-center">
                                    <h2 style="color: white;">活動頁面</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Hero End -->
            <!--? Blog Area Start-->
            <section class="blog_area section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 mb-5 mb-lg-0">
                            <div class="blog_left_sidebar">
                                <router-view></router-view>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="blog_right_sidebar">
                                <!-- CATEGORY -->
                                <aside class="single_sidebar_widget post_category_widget">
                                    <h4 class="widget_title" style="color: #2d2d2d;">活動分類</h4>
                                    <ul class="list cat-list">
                                        <li>
                                            <a @click="()=>route(`/blog/detail`)" class="d-flex">
                                                <p>全部</p>
                                                <p>({{ totolSumAll }})</p>
                                            </a>
                                        </li>
                                        <li v-for="item in categoriesList">
                                            <a @click="()=>route(`/blog/detail/${item.category}`)" class="d-flex">
                                                <p>{{item.category}}</p>
                                                <p>({{ item.value }})</p>
                                            </a>
                                        </li>
                                    </ul>
                                </aside>

                                <!-- RECENT -->
                                <aside class="single_sidebar_widget popular_post_widget">
                                    <h3 class="widget_title" style="color: #2d2d2d;">猜你想看</h3>
                                    <div class="media post_item" v-for="item in artRandamList">
                                        <img :src="item.image" style="width: 80px;height: 80px;object-fit: cover;" alt="post">
                                        <div class="media-body">
                                            <a :href="item.link">
                                                <h3 style="color: #2d2d2d;">{{item.title}}</h3>
                                            </a>
                                            <p>{{item.date}}</p>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Blog Area End -->


        </main>
        <div class="container-fluid bg-light mt-5 py-5">
            <div class="container pt-5">
                <div class="row g-5">
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-uppercase border-start border-5 border-primary ps-3 mb-4"><b>聯係我們</b></h4>
                        <p class="mb-2"><i class="bi bi-geo-alt text-primary me-2"></i>email：bikeangel_b@163.com</p>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-uppercase border-start border-5 border-primary ps-3 mb-4"><b>快速訪問</b></h4>
                        <div class="d-flex flex-column justify-content-start">
                            <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>主頁</a>
                            <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>關於我們</a>
                            <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>相關活動</a>
        
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-uppercase border-start border-5 border-primary ps-3 mb-4"><b>最多訪問</b></h4>
                        <div class="d-flex flex-column justify-content-start">
                            <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>主頁</a>
                            <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>關於我們</a>
                            <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>相關活動</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-uppercase border-start border-5 border-primary ps-3 mb-4"><b>通訊</b></h4>
                        <h2 class="text-uppercase mt-4 mb-3">Follow Us</h2>
                        <div class="d-flex">
                            <a class="btn btn-outline-primary btn-square me-" href="#"><i class="bi bi-twitter"></i></a>
                            <a class="btn btn-outline-primary btn-square me-2" href="#"><i class="bi bi-facebook"></i></a>
                            <a class="btn btn-outline-primary btn-square me-2" href="#"><i class="bi bi-linkedin"></i></a>
                            <a class="btn btn-outline-primary btn-square" href="#"><i class="bi bi-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-dark text-white-50 py-4">
            <div class="container">
                <div class="row g-5">
                    <div class="col-md-6 text-center text-md-start">
                        <p class="mb-md-0">&copy; <a class="text-white" href="https://beian.miit.gov.cn/">粤ICP备2021027931号-1</a></p>
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <p class="mb-0">Designed by <a class="text-white" href="https://htmlcodex.com">Design By 單車天使</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div id="back-top" >
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
        </div>
    </div>
</template>


<script>
import router from '@/router';

export default{
    data(){
        return{
            categoriesList:[],
            articlesList:[],
            artRandamList:[],
        }
    },
    methods:{
        async fetch(){
            await this.$http({
                method: "GET",
                url:`/articles/${'category'}`
            }).then(res=>{
                console.log(res.data)
                this.categoriesList = res.data;
            }).catch(err=>{
                err
            })
            var articlesModels = []
            await this.$http({
                method: "GET",
                url:'/articles/list'
            }).then(res=>{
                articlesModels = res.data.reverse();
                for(var i = 0;i<res.data.length;i++){
                    this.articlesList[i] = articlesModels[i];
                }
                this.getRandomArticles((results)=>{
                    this.artRandamList = results;
                })
            }).catch(err=>{
                err
            })
        },
        route(item){
            this.$router.push(item);
            location.reload();
        },
        getRandomArticles(callback){
            var oldArr = this.articlesList;
            var newArr = [];
            for(var i = 0;i < 5 ; i++){
                var index = Math.floor(Math.random()*oldArr.length);
                var item = oldArr[index];
                newArr.push(item);
                oldArr.splice(index,1);
            }
            callback(newArr)
        }
    },
    created(){
        this.fetch()
    },
    computed:{
        totolSumAll(){
            let totol = 0;
            this.categoriesList.map((item)=>{
                totol += item.value
            })
            return totol;
        }
    }
}
</script>

<style>
.logo_an {
  position: relative;
  display: inline-block;
}
.logo_an_content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
}
.logo_an:hover .logo_an_content {
  display: block;
}
</style>