<template>
    <div>
        <!--? Hero Start -->
        <div class="slider-area2">
            <div class="slider-height2 d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap hero-cap2 pt-20 text-center">
                                <h2 style="color: white;">文章頁面</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero End -->

              <!--? Blog Area Start -->
        <section class="blog_area single-post-area section-padding">
            <div class="container">
                <div class="row">
                <div class="col-lg-8 posts-list">
                    <div class="single-post">
                        <div class="feature-img">
                            <img class="img-fluid" src="assets/img/blog/single_blog_1.png" alt="">
                        </div>
                        <div class="blog_details">
                            <h2 style="font-size: 300%; color: #2d2d2d;">單車天使小勇士開始報名啦！！！
                            </h2>
                            <ul class="blog-info-link mt-3 mb-4">
                            <!-- <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                            <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li> -->
                            </ul>
                            <p class="excert">
                            報名文件分爲：
                            <br>
                            1、初測登記表
                            <br>
                            2、學生家長確認申請簽名涵
                            <br>
                            3、報名表-校醫護理師意見
                            <br>
                            點擊下方的下載按鈕后，會下載一個名爲“單車天使報名文件.zip”，將這些文件解壓后就可以進行報名表的填寫啦！
                            </p>
                            <p><img src="../static/img/DownloadFile/appliesborder.png" alt=""></p>
                            <h1 style="color: red;">
                            點擊此處下載報名文件：
                            </h1>
                            <a-button style="height: 5rem;" type="primary" @click="fileDownload">點此下載報名文件</a-button>

                            <p class="excert">請認真填寫哦！</p>
                        </div>
                    </div>

                </div>
                
                </div>
            </div>
        </section>
      <!-- Blog Area End -->
    </div>
</template>

<script>
export default{
    methods:{
        async fileDownload(){
            var downloadFileName = 'baoming.zip'
            await this.$http({
                methods:"GET",
                url:`/download_1/${downloadFileName}`,
                responseType: 'blob'
            }).then(res=>{
                console.log(res.data);
                const blob = new Blob([res.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);
                this.fileDownloading(url, '單車天使報名文件');
            }).catch(err=>{
                console.log(err);
            })
        },
        fileDownloading(blobUrl, name){
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = blobUrl;
            link.download = `${name}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>