import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import http from './http'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import less from 'less'
import 'ant-design-vue/dist/antd.less'

Vue.directive('title', {//单个修改标题
  inserted: function (el, binding) {
  document.title = el.dataset.title
  }
 })
 
Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.use(Antd);
Vue.use(less);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
