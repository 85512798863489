<template>
    <div class="blogdetail">
        <div class="col-lg-12" >
            <h1 style="padding-bottom: 2rem;">{{category}}文章搜索：</h1>
            <a-input-search size="large" placeholder="請輸入文章標題進行搜索"   @search="onSearch"/>
        </div>
        <br>
        <br>
        <br>
        <br>
        <article class="blog_item" v-for="item in articlesSearched">
            <div class="blog_item_img">
                <img class="card-img rounded-0" :src="item.image" alt="">
                <a :href="item.link" class="blog_item_date">
                    <h3>{{item.category}}</h3>
                </a>
            </div>
            <div class="blog_details">
                <a class="d-inline-block" :href="item.link">
                    <h2 class="blog-head" style="color: #2d2d2d;">{{item.title}}</h2>
                </a>
                <p>{{ item.body }}</p>
                <ul class="blog-info-link">
                    <li><a href="#"><i class="fa fa-hourglass-half"></i>{{ item.date }}</a></li>
                </ul>
            </div>
        </article>

    </div>
</template>

<script>
export default{
    props:{
        category:{}
    },
    data(){
        return {
            awesome:true,
            articlesList:[],
            articlesNow:[],
            articlesSearched:[]
        }
    },
    methods:{
        async fetch(){
            if(!this.category){
                await this.$http({
                    method: "GET",
                    url:'/articles/list'
                }).then(res=>{
                    this.articlesList = res.data.reverse();
                    this.articlesSearched = this.articlesList;
                }).catch(err=>{
                    err
                })
            }
            else{
                await this.$http({
                    method:'GET',
                    url:`/articles/category/${this.category}`
                }).then(res=>{
                    this.articlesList = res.data.reverse();
                    this.articlesSearched = this.articlesList;
                })
            }
        },
        onSearch(value){
            // var value = e.target.value;
            this.articlesSearched = []
            console.log(value)
            var items = new Array()
            items = this.articlesList;
            console.log(items)
            if(value ===''){
                console.log('你好')
                this.articlesSearched = this.articlesList
                console.log(this.articlesSearched)
            }
            else{
                console.log("再见")
                console.log(items)
                items.forEach((element)=>{
                    if(element.title.includes(value)){
                        this.articlesSearched.push(element)
                    }
                })
                console.log(this.articlesSearched)
            }
        }

    },
    created(){
        this.fetch();
    },
    computed:{
       
    }
}
</script>