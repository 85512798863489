import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import HeaderView from '../views/HeaderView.vue'

import HelloView from '../views/HelloView.vue'
import BlogView from '../views/BlogView.vue'
import AboutView from '../views/AboutView.vue'
import PictureView from '../views/PictureView.vue'
import ContactView from '../views/ContactView.vue'

import BlogArticleDetail from  '../views/BlogArticleDetail.vue'

import BlogDetailView from '../views/BlogdetailView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HeaderView,
    redirect: '/hello',
    children:[
      {path:'/hello', component: HelloView},
      {path:'/about', component: AboutView},
      {path:'/photos', component: PictureView},
      {path:'/contact', component: ContactView},
      {path:'/articles', component: BlogArticleDetail}
    ]
  },
  {
    path: '/blog',
    component: BlogView,
    redirect: '/blog/detail',
    children:[
      {path:'detail', component: BlogDetailView},
      {path:'detail/:category', component: BlogDetailView, props:true}
    ]
  }
]

const router = new VueRouter({
  mode:'hash',
  routes
})

export default router
