<template>
    <div class="about">
        
        <main>
    <!--? Hero Start -->
    <div class="slider-area2">
        <div class="slider-height2 d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap hero-cap2 pt-20 text-center">
                            <h2 style="color: white;">故事墻</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero End -->
    <!--? Testimonial Start -->
    <div class="testimonial-area testimonial-padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-5 col-lg-6 col-md-9 col-sm-10">
                    <div class="section-tittle text-center mb-90">
                        <span>關於我們</span>
                        <h2>單車天使</h2>
                    </div>
                </div>
            </div>
            <!-- Testimonial contents -->
            <div class="row d-flex justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-10">
                    <div class="h1-testimonial-active dot-style">
                        <!-- Single Testimonial -->
                        <div class="single-testimonial text-center">
                            <div class="testimonial-caption ">
                                <!-- founder -->

                                <div class="testimonial-top-cap">
                                    <p>台灣單車天使神州圓夢公益之旅的主旨是幫助兩岸青少年實現騎行神州的夢想，加强兩岸青少年文化交流</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonial End -->
    <!--? Testimonial Start -->
    <div class="testimonial-area testimonial-padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-5 col-lg-6 col-md-9 col-sm-10">
                    <div class="section-tittle text-center mb-90">
                        <span>單車天使</span>
                        <h2>我們的故事</h2>
                    </div>
                </div>
            </div>
            <!-- Testimonial contents -->
            <div class="row d-flex justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-10">
                    <div class="h1-testimonial-active dot-style">
                        <!-- Single Testimonial -->
                        <div v-for="item in storyList" class="single-testimonial text-center">
                            <div class="testimonial-caption ">
                                <!-- founder -->
                                <div class="testimonial-founder">
                                    <div class="founder-img mb-40">
                                        <img style="width: 50%;" :src="item.image" alt="">
                                        <span>{{ item.title }}</span>
                                    </div>
                                </div>
                                <div class="testimonial-top-cap">
                                    <p>{{ item.body }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonial End -->


    </main>
    </div>
</template>

<script>
export default{
    data(){
        return {
            storyList: [],
        }
    },
    methods: {
        async fetch(){
            await this.$http({
                method: "GET",
                url:'/stories/list'
            }).then(res=>{
                this.storyList = res.data;
            }).catch(err=>{
                err
            })
        }
    },
    created(){
        this.fetch()
    }
}
</script>