<template>
    <div class="about">
        
        <main>
    <!--? Hero Start -->
    <div class="slider-area2">
        <div class="slider-height2 d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap hero-cap2 pt-20 text-center">
                            <h2 style="color: white;">照片墻</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero End -->
    <!-- Our Cases Start -->
    <div class="our-cases-area section-padding30">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-7 col-md-10 col-sm-10">
                    <!-- Section Tittle -->
                    <div class="section-tittle text-center mb-80">
                        <span>我們的孩子</span>
                        <h2>單車天使的小勇士們</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-for="(item, index) in picturesList" class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cases mb-40">
                        <div class="cases-img">
                            <img style="object-fit:cover; width: 360px; height: 240px;" :src="item.image" alt="">
                        </div>
                        <div class="cases-caption">
                            <!-- Progress Bar -->
                            <div class="single-skill mb-15">
                                <div class="bar-progress">
                                    <div id="bar1" class="barfiller">
                                        <div class="tipWrap">
                                            <span class="tip"></span>
                                        </div>
                                        <span class="fill" data-percentage="70"></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Our Cases End -->


    </main>
    </div>
</template>

<script>
export default{
    data(){
        return {
            picturesList: [],
        }
    },
    methods: {
        async fetch(){
            await this.$http({
                method: "GET",
                url:'/pictureswall/list'
            }).then(res=>{
                this.picturesList = res.data;
                console.log(this.picturesList)
            }).catch(err=>{
                err
            })
        }
    },
    created(){
        this.fetch()
    }
}
</script>