<template>
    <div class="hello">
        <main>
    <!-- slider Area Start-->
    <div class="slider-area">
        <div class="slider-active">

            <!-- Single Slider -->
            <div class="single-slider slider-height d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                            <div class="hero__caption">
                                <h1 data-animation="fadeInUp" data-delay=".6s">單車天使</h1>
                                <p style="font-size: 3rem; color: rgb(20, 20, 20);" data-animation="fadeInUp" data-delay=".8s" ><b style="color:rgb(73, 73, 73)">單車天使神州圓夢公益之旅</b><br><br>我們一直在路上>></p>
                                <!-- Hero-btn -->
                                <div class="hero__btn">
                                    <router-link to="/about" class="btn hero-btn mb-10"  data-animation="fadeInLeft" data-delay=".8s">了解更多</router-link>
                                    <!-- <a href="industries.html" class="cal-btn ml-15" data-animation="fadeInRight" data-delay="1.0s"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--? About Law Start-->
    <section class="about-low-area section-padding2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-10">
                    <div class="about-caption mb-50">
                        <!-- Section Tittle -->
                        <div class="section-tittle mb-35">
                            <span>相關視頻</span>
                            <h2>單車天使的第十一屆紀錄片</h2>
                        </div>
                        <p>【单车天使】第十一届单车天使公益圆梦骑行活动圆满落幕</p>
                    </div>
                    <a href="https://www.bilibili.com/video/BV1ww411U738/?spm_id_from=333.999.0.0&vd_source=b4eda7a09947524145610dcd1b2f0bcf" class=" btn"><i class="bi bi-caret-right-square-fill"></i> 觀看視頻</a>
                </div>
                <div class="col-lg-6 col-md-12">
                    <!-- about-img -->
                    <div class="about-img ">
                        <div class="about-font-img d-none d-lg-block">
                            <img src="../static/img/gallery/about2.png" alt="">
                        </div>
                        <div class="about-back-img ">
                            <img src="../static/img/gallery/about1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Law End-->
    <!-- Our Cases Start -->
    <div class="our-cases-area section-padding30">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-7 col-md-10 col-sm-10">
                    <!-- Section Tittle -->
                    <div class="section-tittle text-center mb-80">
                        <span>我們的孩子</span>
                        <h2>單車天使的小勇士們</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-for="(item, index) in picturesList" v-if="index<5" class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cases mb-40">
                        <div class="cases-img">
                            <img style="object-fit:cover; width: 360px; height: 240px;" :src="item.image" alt="">
                        </div>
                        <div class="cases-caption">
                            <!-- Progress Bar -->
                            <div class="single-skill mb-15">
                                <div class="bar-progress">
                                    <div id="bar1" class="barfiller">
                                        <div class="tipWrap">
                                            <span class="tip"></span>
                                        </div>
                                        <span class="fill" data-percentage="70"></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Our Cases End -->


    <!--? Blog Area Start -->
    <section class="home-blog-area section-padding30">
        <div class="container">
            <!-- Section Tittle -->
            <div class="row justify-content-center">
                <div class="col-xl-5 col-lg-6 col-md-9 col-sm-10">
                    <div class="section-tittle text-center mb-90">
                        <span>近期文章</span>
                        <h2>我們最近的事兒</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-for="(item, index) in blogList" v-if="index<2" class="col-xl-6 col-lg-6 col-md-6">
                    <div class="home-blog-single mb-30">
                        <div class="blog-img-cap">
                            <div class="blog-img">
                                <img :src="item.image" alt="">
                                <!-- Blog date -->
                                <div class="blog-date text-center">
                                    <span>{{ item.category }}</span>
                                </div>
                            </div>
                            <div class="blog-cap">
                                <p>{{item.date}}</p>
                                <h3><a :href="item.link">{{ item.title }}</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Blog Area End -->
        <!--? Services Area Start -->
        <div class="service-area section-padding30">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-7 col-md-10 col-sm-10">
                    <!-- Section Tittle -->
                    <div class="section-tittle text-center mb-80">
                        <span>關於我們</span>
                        <h2>從下方瞭解我們</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cat text-center mb-50">
                        <div class="cat-icon">
                            <span class="bi bi-arrow-down-left-square"></span>
                        </div>
                        <div class="cat-cap">
                            <h5><router-link to="/about">關於我們</router-link></h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cat active text-center mb-50">
                        <div class="cat-icon">
                            <span class="bi bi-arrow-down-square"></span>
                        </div>
                        <div class="cat-cap">
                            <h5><router-link to="/">首頁</router-link></h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-cat text-center mb-50">
                        <div class="cat-icon">
                            <span class="bi bi-arrow-down-right-square"></span>
                        </div>
                        <div class="cat-cap">
                            <h5><a href="/blog">相關活動</a></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Services Area End -->
        
    </main>
    </div>
</template>

<script>
export default{
    data(){
        return {
            picturesList:[],
            blogList:[],
        }
    },
    methods:{
        async fetch(){
            await this.$http({
                method: "GET",
                url:'/pictureswall/list'
            }).then(res=>{
                this.picturesList = res.data;
                console.log(this.picturesList)
            }).catch(err=>{
                err
            })

            await this.$http({
                method: "GET",
                url:'/articles/list'
            }).then(res=>{
                this.blogList = res.data.reverse();
            }).catch(err=>{
                err
            })
        }
    },
    created(){
        this.fetch();
    }
}
</script>


<style>
.slider-area {
  background-image: url(../static/img/hero/hero.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
